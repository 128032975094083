import { createAction, props } from '@ngrx/store';
import { IdentifiedAssistEvent } from '../models';
import { OrderDescriptor, WebexUserSettingsModel } from '@cue/api';
import { CalendarAttendee } from '@cue/calendars';

export const loadEventsPatched = createAction('[Events] Load', props<{ startFrom?: Date; endFrom?: Date }>());
export const eventsLoaded = createAction('[Events] Loaded', props<{ events: IdentifiedAssistEvent[] }>());

export const deleteEventById = createAction(
  '[Events] Delete By Id',
  props<{
    eventToDeleteId: string;
    email: string;
    icalId: string;
    showAs: string;
    subject: string;
    start: Date;
    end: Date;
    roomName: string;
    roomEmail: string;
    roomUsername: string;
  }>(),
);

export const cancelParticipationEventById = createAction(
  '[Events] Cancel participation Event By Id',
  props<{
    eventToCancelParticipationId: string;
    icalId: string;
  }>(),
);

export const finishEventById = createAction('[Events] Finish By Id', props<{ eventToFinishId: string }>());

export const even = createAction('[Events] Finish By Id', props<{ eventToDeleteId: string }>());
export const eventDeleteFailed = createAction('[Events] Event Delete Failed', props<{ eventToDeleteId: string }>());

export const eventCancelParticipationFailed = createAction(
  '[Events] Event Cancel Participation Failed',
  props<{ eventToCancelParticipationId: string }>(),
);

export const createEvent = createAction(
  '[Events] Create',
  props<{
    visibility: 'public' | 'private';
    orders: OrderDescriptor[];
    showAs: { key: string; value: string };
    subject: string;
    text: string;
    start: Date;
    end: Date;
    roomName: string;
    roomEmail: string;
    roomUsername: string;
    attendees: {
      isResource: boolean;
      address: string;
      name: string;
      type: 'required' | 'optional';
    }[];
    onlineMeeting?: {
      onlineMeetingProvider: string;
    };
    webexMeeting?: WebexUserSettingsModel;
  }>(),
);

export const notFoundEvent = createAction('[Events] Event not Found');

export const eventCreated = createAction(
  '[Events] Event Created',
  props<{
    id: string;
    start: Date;
    end: Date;
  }>(),
);

export const eventPatched = createAction(
  '[Events] Event patched',
  props<{
    id: string;
    start: Date;
    end: Date;
  }>(),
);

export const eventResponded = createAction(
  '[Events] Event Responded',
  props<{
    id: string;
    attendee: CalendarAttendee;
    showChangeButton: boolean;
  }>(),
);

export const eventCreationFailed = createAction('[Events] Event Creation Failed');
