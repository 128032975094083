import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GroupedInstructionItem } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NavigationMovementService implements OnDestroy {
  private subscriptionList: Subscription[] = [];
  private currentAreaGroupIdx$: BehaviorSubject<number> = new BehaviorSubject(0);

  private areaInfoList: GroupedInstructionItem[] = [];

  private _currentAreaGroupIdx: number;
  get currentAreaGroupIdx(): number {
    return this._currentAreaGroupIdx;
  }

  private set currentAreaGroupIdx(value: number) {
    this._currentAreaGroupIdx = value;
  }

  selectedDirectionItemIdx$: BehaviorSubject<number> = new BehaviorSubject(-1);
  currentAreaId$: BehaviorSubject<number> = new BehaviorSubject(-1);

  constructor() {
    this.subscriptionList.push(
      this.currentAreaGroupIdx$.subscribe((x) => {
        this.currentAreaGroupIdx = x;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((sub) => sub.unsubscribe());
  }

  moveToPreviousAreaGroup(): number {
    const newAreaGroupIdx = this.currentAreaGroupIdx > 0 ? this.currentAreaGroupIdx - 1 : 0;
    this.currentAreaGroupIdx$.next(newAreaGroupIdx);

    return this.areaInfoList[newAreaGroupIdx].areaId;
  }

  moveToNextAreaGroup(): number {
    let newAreaGroupIdx = this.currentAreaGroupIdx;
    if (this.currentAreaGroupIdx < this.areaInfoList.length - 1) {
      newAreaGroupIdx = this.currentAreaGroupIdx + 1;
      this.currentAreaGroupIdx$.next(newAreaGroupIdx);
    }

    return this.areaInfoList[newAreaGroupIdx].areaId;
  }

  setCurrentAreaGroupIndex(value: number) {
    this.currentAreaGroupIdx$.next(+value);
  }

  moveToFirstNavigationGroupOfArea(areaId: number) {
    if (this.areaInfoList?.length > 0) {
      this.currentAreaGroupIdx$.next(this.areaInfoList.findIndex((x) => x.areaId == areaId));
    } else {
      this.currentAreaGroupIdx$.next(0);
    }
  }

  moveToFirstPreviousAreaOfCurrent(areaId: number) {
    const areaListWithIndexes = this.areaInfoList.map((x, index) => {
      return {
        idx: index,
        data: x,
      };
    });

    const newAreaGroupIdx = areaListWithIndexes.slice(0, this.currentAreaGroupIdx).find((x) => x.data.areaId === areaId)?.idx;
    if (newAreaGroupIdx != null) {
      this.currentAreaGroupIdx$.next(newAreaGroupIdx);
    }
  }

  moveToFirstNextAreaOfCurrent(areaId: number) {
    const areaListWithIndexes = this.areaInfoList.map((x, index) => {
      return {
        idx: index,
        data: x,
      };
    });

    const newAreaGroupIdx = areaListWithIndexes.slice(this.currentAreaGroupIdx + 1).find((x) => x.data.areaId === areaId)?.idx;
    if (newAreaGroupIdx != null) {
      this.currentAreaGroupIdx$.next(newAreaGroupIdx);
    }
  }

  setAreaInfoList(dataList: GroupedInstructionItem[]) {
    this.areaInfoList = dataList;
  }
}
