import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { from, map, Observable, of, Subject, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { AssistEvent } from '../models';
import { assistDB, AssistLog } from './assistDB';
import { PromiseExtended } from 'dexie';

@Injectable()
export class AssistErrorHandler implements ErrorHandler {
  error$: Subject<any> = new Subject();
  events$: Subject<AssistEvent[]> = new Subject();

  constructor(private zone: NgZone) {
    this.events$
      .pipe(
        switchMap((events) => this.getLoggedEvents().pipe(map((loggedEvents) => [events, loggedEvents != null ? loggedEvents : []]))),
        map(([newEvents, loggedEvents]) => {
          if (loggedEvents.length > AssistErrorHandler.MAX_ITEMS) {
            loggedEvents = loggedEvents.slice(loggedEvents.length - AssistErrorHandler.MAX_ITEMS);
          }
          loggedEvents.push(...newEvents);
          return loggedEvents;
        }),
        switchMap((log) => this.writeEventsLog(log)),
      )
      .subscribe();

    this.error$
      .pipe(
        switchMap((error) => this.getLog().pipe(map((log) => [error, log != null ? log : []]))),
        map(([error, log]) => {
          if (log.length > AssistErrorHandler.MAX_ITEMS) {
            log = log.slice(log.length - AssistErrorHandler.MAX_ITEMS);
          }
          log.push(error);
          return log.map((it, i) => ({ ...it, id: i }));
        }),
        switchMap((log) => this.writeLog(log)),
      )
      .subscribe();
  }

  static LOG_KEY = 'ASSIST_LOG';
  static LOG_EVENTS_KEY = 'ASSIST_LOG_EVENTS';
  static MAX_ITEMS = 100;

  handleError(error) {
    this.zone.runOutsideAngular(() => {
      this.error$.next({
        date: new Date(),
        error: {
          message: error.message,
          stack: error.stack,
        },
      } as AssistLog);

      console.error(error);
    });
  }

  handleEvents(events: AssistEvent[]) {
    this.events$.next(events);
    console.error(events);
  }

  getLog() {
    return from(assistDB.logs.toArray());
  }

  writeLog(log: AssistLog[]) {
    return from(assistDB.logs.clear()).pipe(switchMap(() => from(assistDB.logs.bulkPut(log))));
  }

  getLoggedEvents() {
    return from(assistDB.events.toArray() as unknown as PromiseExtended<AssistEvent[]>);
  }

  writeEventsLog(events: AssistEvent[]) {
    return from(assistDB.events.clear()).pipe(switchMap(() => from(assistDB.events.bulkPut(events))));
  }
}
