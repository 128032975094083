import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';
import { IdentifiedAssistEvent, Pack } from '../models';

export const colleagueEvents = createSelector(selectShared, (state) => state.colleagueEvents);

export const filteredColleagueEvents = createSelector(colleagueEvents, (packedEvents) => {
  if (packedEvents.loading) {
    return packedEvents;
  }

  const multiEvents: {
    [icalId: string]: any;
  } = {};

  const addToMultiEvents = (ical, event) => {
    if (multiEvents[ical]) return;
    if (new Date(event.start.dateTime) >= new Date() || new Date(event.end.dateTime) >= new Date()) {
      multiEvents[ical] = event;
    }
  };
  packedEvents.data.forEach((event) => {
    if (event.type === 'occurrence') {
      addToMultiEvents(event.iCalUId, event);
    }
  });

  const uniqueFiltered = packedEvents.data.filter(
    (event) =>
      (new Date(event.start.dateTime) >= new Date() || new Date(event.end.dateTime) >= new Date()) &&
      (event.type !== 'occurrence' || (event.type === 'occurrence' && multiEvents[event.id].start.dateTime === event.start.dateTime)),
  );

  return {
    loading: packedEvents.loading,
    data: packedEvents.data != null ? uniqueFiltered : null,
  };
});
